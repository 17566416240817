import React from 'react'
import { graphql } from 'gatsby';
import Default from 'gatsby-theme-carbon/src/templates/Default';
import {PageDescription, Video, Row, Column} from 'gatsby-theme-carbon';
import {P, H2, H4, Blockquote} from 'gatsby-theme-carbon/src/components/markdown';
import {Link} from 'carbon-components-react';
import { ArrowRight16 } from '@carbon/icons-react';
import { getNarrative, getStoryData, slugify } from './../util';
import StoryCard from './../components/story-card'

const getVimeoID = (vimeo) => {
	if(!vimeo) return null;
	let url = vimeo.uri
	if(!url || !url.length) return null;
	let result = url.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i);
	if(!result) return null;
	return result[1];
}

const getQuote = (story) => {
	let quote = null;
	if(story.field_story_quote && story.field_story_quote.length){
		quote = {
			content: story.field_story_quote
		}
		if(story.field_story_quote_attribution && story.field_story_quote_attribution.length)
			quote.attribution =  story.field_story_quote_attribution
	}
	return quote
}

const getRelatedStories = (story) => {
	let story_related = []
	if(story.relationships && story.relationships.field_story_related){
		story_related = story.relationships.field_story_related.map((node) => {
			return getStoryData(node);
		})
	}
	return story_related
}

const Story = ({ data, location, pageContext }) => {

	const category_slug = data.story.relationships.field_story_career ? slugify(data.story.relationships.field_story_career.relationships.field_career_category.name) : null;
	
	if(!category_slug) return (<></>);
	
	let thumbnail_url = data.story.relationships.field_story_thumbnail && data.story.relationships.field_story_thumbnail.localFile ? data.story.relationships.field_story_thumbnail.localFile.url : '';
	let thumbnail_alt = data.story.field_story_thumbnail && data.story.field_story_thumbnail.alt && data.story.field_story_thumbnail.alt.length > 0 ? data.story.field_story_thumbnail.alt : '';
	if(thumbnail_alt.length === 0 && thumbnail_url.length > 0) thumbnail_alt = data.story.relationships.field_story_thumbnail.localFile.name;


	const story_data = {
		...data.story,
		thumbnail: thumbnail_url,
		story_first_name: data.story.field_story_name.split(' ')[0],
		story_search_url: `/job-search?category=${category_slug}`,
		vimeo_id: getVimeoID(data.story.field_story_video),
		narrative: getNarrative(data.story.field_story_description),
		story_quote: getQuote(data.story),
		story_related: getRelatedStories(data.story),
		alt: thumbnail_alt
	};

	// console.log('story_data', data);

	let page_title = story_data.field_story_name
	if(story_data.field_story_job_title) page_title += `, ${story_data.field_story_job_title}`
	pageContext = {...pageContext, frontmatter:
		{
		  title: page_title
		}
	};

	const renderNarrative = () => {
		return story_data.narrative.map((block) => {
			return (
				<div key={Math.random()}>
					{block.title.length > 0 && <H2>{block.title}</H2>}
					<div className="richtext-body" dangerouslySetInnerHTML={{__html: block.content}}></div>
				</div>
			)
		})	
	}

	const renderRelatedStories = () => {
		return story_data.story_related.map((rel_story) => <Column key={rel_story.nid} colMd={4} colLg={4}>
			<StoryCard story_data={rel_story} />
		</Column>)
	}	

	return (
		<Default pageContext={pageContext} location={location}>
			{story_data.field_story_intro && <PageDescription>
				<div dangerouslySetInnerHTML={{__html: story_data.field_story_intro.processed}}></div>
			</PageDescription>}
	
			{story_data.vimeo_id && <Video vimeoId={story_data.vimeo_id} />}
			
			{story_data.story_quote && 
			<Blockquote>
				{story_data.thumbnail && <span className="img" style={{backgroundImage: `url(${story_data.thumbnail})`}}><img src={story_data.thumbnail} alt={story_data.alt} /></span>}
				<div>
					<P>{story_data.story_quote.content}</P>
					{story_data.story_quote.attribution && <cite>{story_data.story_quote.attribution}</cite>}
				</div>
			</Blockquote>}
			
			{story_data.narrative.length > 0 && renderNarrative()}

			{story_data.story_related[0] && <div className="story-related">
				<H4>See other success stories:</H4>
				<Row>
					{renderRelatedStories()}
				</Row>
			</div>}

			<div  className="callout-link"><Link href={story_data.story_search_url} inline={false}><span>{`See jobs like ${story_data.story_first_name}'s that are currently open`}</span> <ArrowRight16 /></Link></div>

		</Default>
	)
}


export default Story;

export const query = graphql`
  query($slug: String!) {
    story: nodeSuccessStory(fields: { slug: { eq: $slug } }) {
		id
		drupal_id
		field_story_name
		field_story_quote
		field_story_quote_attribution
		field_story_job_title
		field_story_video {
			uri
		}
		field_story_description {
			processed
		}
		field_story_intro {
			processed
		}
		field_story_thumbnail {
			alt
		}
		relationships {
			field_story_related {
			  drupal_internal__nid
			  title
			  field_story_name
			  field_story_job_title
			  field_story_thumbnail {
				alt
			  }
			  relationships {
				field_story_career {
				  title
				}
				field_story_thumbnail {
					localFile {
						url
						name
					}
				}
			  }
			}
			field_story_thumbnail {
				localFile {
				  url
				  name
				}
			}
			field_story_career {
				relationships {
					field_career_category {
						drupal_internal__tid
						name
					}
				}
			}
		}
    }
  }
`